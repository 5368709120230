import { nanoid } from 'nanoid';
import splace from '../assets/logosplace.png'
// HEAD DATA
export const headData = {
  title: 'Mohamed Amine BEN SOLTANA', // e.g: 'Name | Developer'
  lang: 'fr', // e.g: en, es, fr, jp
  description: 'bienvenue dans mon portfolio', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Bonjour, je suis',
  name: 'Mohamed Amine BEN SOLTANA',
  subtitle: 'je suis un développeur informatique',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'nature calls.jpg',
  paragraphOne: 'Je suis un développeur informatique et étudiant à l\'université sorbonne paris Nord, je suis à la recherche d\'un contrat d\'alternance dans le développement de sites web ou d\'applications mobiles. ' 
  ,
  paragraphTwo: 'Je suis intéressé par le développement de jeux video et l\'art digital. Je suis quelqu\'un de créatif et de très sérieux dans mon travail et je suis toujours à la recherche de nouvelles innovations et technologies.',
 // paragraphThree: 'Télécharger mon cv ci-dessous pour savoir plus ',
  cv:'https://drive.google.com/file/d/1PRsZAfMTF-RyXvx-agi57keVAvdzGZxm/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: '1clic.PNG',
    title: '1 clic 1 prof',
    info: ' ',
    info2: 'Site internet de cours particuliers developpé en utilisent le cms Wordpress et le plug-in Elementor',
    url: 'https://1clic1prof.fr/',
    repo: '', 
  },
  {
    id: nanoid(),
    img: 'produitss.PNG',
    title: 'Produits',
    info: 'Application Android programmé en Java qui permettre d\'ajouter et modifier des éléments dans une liste.',
    info2: '',
    url: 'https://github.com/Benso1tana/Produits',
    repo: '', 
  },
  {
    id: nanoid(),
    img: 'mmo.jpg',
    title: 'MMORPGVR',
    info: 'Jeu MMORPG/rogue-like programmé en Java, le but de jeu consiste a placer le joueur dans une carte générée de façon aléatoire avec des objets et des monstres générés aléatoirement disséminés sur cette carte. Après avoir créé son personnage, le joueur doit vaincre tous les monstres pour gagner la partie.',
    info2: '',
    url: 'https://github.com/Benso1tana/MMORPGVR',
    repo: '', 
  },
  {
    id: nanoid(),
    img: 'jeucaval.JPG',
    title: 'Jeu Cavalier',
    info: 'application graphique WinForms (C#), qui permet de faire parcourir à un cavalier l\'ensemble d\'un échiquier sans passer deux fois sur la même case.',
    info2: '',
    url: 'https://github.com/Benso1tana/Jeu_Cavalier',
    repo: '', 
  }
];
  // SKILLS DATA
export const skillsData = [
  'python',
  'javascript',
  'css3',
  'csharp',
  'html5',
  'php',
  'cplusplus',
  'java',
  "jquery",
  'mysql',
  'react',
  'redux',
  'mongodb',
  'bash',
  'git',
  'postgresql',



  
];

  // Softs DATA
  export const softwaresData = [
    
  {logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg",name: 'Figma',link:"https://www.figma.com/"},
  {logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/photoshop/photoshop-plain.svg",name: 'photoshop',link:"https://www.photoshop.com/"},
  {logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original-wordmark.svg" ,name: 'Github',link:"https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley"},
  {logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/androidstudio/androidstudio-original.svg" ,name: 'Android Studio',link:"https://developer.android.com/studio/intro"},
  {logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/UE_Logo_Black_Centered.svg/220px-UE_Logo_Black_Centered.svg.png" ,name: 'Unreal Engine',link:"https://www.unrealengine.com/fr-FR/?sessionInvalidated=true"},
  {logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/wordpress/wordpress-original.svg" ,name: 'Wordpress',link:"https://www.wordpress.com"},
  {logo: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg" ,name: 'Visual Studio Code ',link:"https://code.visualstudio.com/"},
  







  
  
    
  ];

  // Exp DATA 
  export const experienceData = [
    {
      name: 'Développeur fullstack React-native',
      type: 'Stage',
      date : '2021',
      duration: '10 semaines',
      entreprise: 'Splace',
      logo : splace,
      description: "Le but général de stage était de développer la version bêta d’une application mobile multi-plateforme en utilisant React-native permettant de mettre en relation tous les sportifs en fonction de critères de recherche, de trouver les meilleurs spots près d'eux et de créer des sessions de sport ou participer aux matchs proposés par les autres utilisateurs de l’app. ",
      tasks: [
        "Schématiser la base de donnés qu’on va utiliser",
        "Réfléchir à des user stories et bien planifier le travail",
        "Optimiser et refactoriser le code existant.",
        "Ajouter des nouvelles fonctionnalités"
      ]

    }
  ]


// CONTACT DATA
export const contactData = {
  cta: 'N\'hésitez pas à me contacter  ',
  btn: 'Envoyer un mail ',
  email: 'medaminebensoltana@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://www.instagram.com/med_amine_bensoltana2.0/',
    },
    
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/mohamed-amine-ben-soltana/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/Benso1tana',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, 
};
